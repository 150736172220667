import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import {createBrowserRouter, RouterProvider} from 'react-router-dom';
import {ThemeProvider} from './components/theme/ThemeProvider';
import Auth from './pages/Auth';
import {initSuperTokens} from './common/superTokens/initSuperTokens';
import {EmailVerificationHandler} from './components/organisms/LoginForms/EmailVerificationHandler';
import {UserContextProvider} from './common/UserContext/UserContextProvider';
import {QueryClient, QueryClientProvider} from '@tanstack/react-query';
import {PasswordResetHandler} from './components/organisms/LoginForms/PasswordResetHandler';
import {Onboarding} from './pages/onboarding/Onboarding';
import {initMixpanel} from './lib/mixpanel/initMixpanel';
import {activateLocale} from './activateLocale';
import {TrackingProvider} from './hooks/useTracking';
import {SnackbarProvider} from './common/snackbar/SnackbarProvider';
import {RedactPost} from './pages/redactpost/RedactPost';
import {FindIdea} from './pages/findIdea/FindIdea';
import {RecyclePost} from './pages/recyclepost/RecyclePost';
import {MyPosts} from './pages/myPosts/MyPosts';
import {Settings} from './pages/settings/Settings';
import {Pricing} from './pages/pricing/Pricing';
import * as Sentry from '@sentry/react';
import {GoogleCallbackHandler} from './components/organisms/LoginForms/GoogleCallbackHandler';
import {SignUpEmailVerification} from './components/organisms/LoginForms/EmailVerification';
import {Home} from './pages/home';
import {Affiliate} from './pages/affiliate/Affiliate';
import {TextFormatter} from './pages/freeTools/TextFormatter';
import {HeadlineGenerator} from './pages/freeTools/HeadlineGenerator';
import {SummaryGenerator} from './pages/freeTools/SummaryGenerator';
import {VideoDownloader} from './pages/freeTools/VideoDownloader';
import {ProfileFeedback} from './pages/freeTools/ProfileFeedback';
import {PostBooster} from './pages/freeTools/PostBooster';

const queryClient = new QueryClient();

initSuperTokens();

import.meta.env.VITE_NODE_ENV !== 'development' &&
  Sentry.init({
    environment: import.meta.env.VITE_NODE_ENV,
    dsn: 'https://618af971376a330beb61497275fc7368@o4507289782648832.ingest.de.sentry.io/4507695494594640',
    ignoreErrors: [
      'Non-Error promise rejection captured with value: Object Not Found Matching Id',
    ],
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.browserProfilingIntegration(),
      Sentry.replayIntegration(),
    ],
    // Performance Monitoring
    tracesSampleRate: 0.1, //  Capture 10% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: ['localhost', 'redactai'],
    // Profiling
    profilesSampleRate: 0.1, // Profile 10% of the transactions. This value is relative to tracesSampleRate
    // Session Replay
    replaysSessionSampleRate: 0.01, // This sets the sample rate at 1%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });

const router = createBrowserRouter([
  {
    path: '/',
    element: <Home />,
  },
  {
    path: '/auth',
    element: <Auth />,
  },
  {
    path: '/auth/verify-email',
    element: <EmailVerificationHandler />,
  },
  {
    path: '/auth/send-email-verification',
    element: <SignUpEmailVerification />,
  },
  {
    path: '/auth/reset-password',
    element: <PasswordResetHandler />,
  },
  {
    path: '/auth/callback/google',
    element: <GoogleCallbackHandler />,
  },
  {
    path: '/onboarding',
    element: <Onboarding />,
  },
  {
    path: '/redactPost',
    element: <RedactPost />,
  },
  {
    path: '/findIdea',
    element: <FindIdea />,
  },
  {
    path: '/recyclePost',
    element: <RecyclePost />,
  },
  {
    path: '/myposts',
    element: <MyPosts />,
  },
  {
    path: '/settings',
    element: <Settings />,
  },
  {
    path: '/pricing',
    element: <Pricing />,
  },
  {
    path: '/affiliate',
    element: <Affiliate />,
  },
  {
    path: '/free-tools/text-formatter',
    element: <TextFormatter />,
  },
  {
    path: '/free-tools/headline-generator',
    element: <HeadlineGenerator />,
  },
  {
    path: '/free-tools/summary-generator',
    element: <SummaryGenerator />,
  },
  {
    path: '/free-tools/video-downloader',
    element: <VideoDownloader />,
  },
  {
    path: '/free-tools/post-booster',
    element: <PostBooster />,
  },
  {
    path: '/free-tools/profile-feedback',
    element: <ProfileFeedback />,
  },
]);

activateLocale();

initMixpanel();

ReactDOM.createRoot(document.getElementById('root')!).render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <TrackingProvider>
        <UserContextProvider>
          <ThemeProvider>
            <SnackbarProvider>
              <RouterProvider router={router} />
            </SnackbarProvider>
          </ThemeProvider>
        </UserContextProvider>
      </TrackingProvider>
    </QueryClientProvider>
  </React.StrictMode>,
);
