export interface SavedPostType {
  id: string;
  authorName: string;
  createdAt: Date;
  postContent: string;
  isUsed: boolean;
  isLiked: boolean | undefined;
  profileUrl?: string;
  language?: SavedPostLanguage;
  isFavourite: boolean;
}

export enum SavedPostTone {
  HUMOROUS = 'Humouristique',
  PERSUASIVE = 'Persuasif',
  IRONIC = 'Ironique',
  INSPIRATIONAL = 'Inspirant',
  PEDAGOGICAL = 'Pédagogique',
  HUMBLE = 'Humble',
  MOTIVATING = 'Motivant',
  ARROGANT = 'Arrogant',
  OPTIMISTIC = 'Optimiste',
  PESSIMISTIC = 'Pessimiste',
  STORYTELLING_INSPIRATIONAL = 'Storytelling / Inspirational',
  SALES_PERSUASIVE = 'Sales / Persuasive',
  ANALYTICAL_PEDAGOGICAL = 'Analytical / Pedagogical',
  TRUTH_ARROGANT = 'Truth / Arrogant',
  FORMAL_SERIOUS = 'Formal / Serious',
  INFORMAL_COOL = 'Informal / Cool',
}

export enum SavedPostPronoun {
  I = 'Je',
  YOU = 'Tu',
  WE = 'Nous',
  YOU_PLURAL = 'Vous',
}

export enum SavedPostLength {
  SHORT = 'Court',
  LONG = 'Long',
}

export enum SavedPostLanguage {
  FRENCH = 'Français',
  ENGLISH = 'Anglais',
  SPANISH = 'Espagnol',
  GERMAN = 'Allemand',
  ITALIAN = 'Italien',
  PORTUGUESE = 'Portugais',
}
