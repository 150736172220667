import {
  FunctionComponent,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import styled from 'styled-components';
import {ButtonTab} from '../atoms/ButtonTab';
import {Chip} from '../atoms/Chip';
import {UpgradePricingCard} from '../molecules/UpgradePricingCard';
import ArrowIndicator from './images/ArrowIndicator';
import {t} from '@lingui/macro';
import {ProductPeriod} from '../../hooks/subscription/useSubscription';
import {Product, useProducts} from '../../hooks/pricing/useProducts';
import {UserContext} from '../../common/UserContext/user.context';
import {useCreateCheckoutSession} from '../../hooks/pricing/createCheckoutSession';
import {ThreeDotsLoader} from '../atoms/ThreeDotsLoader';
import {useTracking} from '../../hooks/useTracking';
import {FilterSelect} from '../../pages/recyclepost/components/FilterSelect';
import {i18n} from '@lingui/core';

const getCurrencySymbol = (
  currency: 'EUR' | 'USD',
  useName: boolean = false,
): string => {
  if (useName) {
    return currency === 'USD' ? 'US dollars' : 'euros';
  }
  return currency === 'USD' ? '$' : '€';
};

const priceToString = (price: number, period?: ProductPeriod): string =>
  price === 0
    ? '0'
    : (price / 100 / (period === ProductPeriod.YEARLY ? 12 : 1)).toFixed(1) +
      '0';

const productToPricePerPost = (product: Product): string => {
  const price = product.price;
  return price === 0
    ? '0'
    : (
        price /
        100 /
        (product.period === ProductPeriod.YEARLY ? 12 : 1) /
        product.posts
      ).toFixed(1) + '0';
};

const creatorsProfileImageUrls = [
  'https://cdn.prod.website-files.com/661d0e82859b70eb49028b49/6634f8c9ef5c1e4220d4758b_1712757424735-p-500.jpg',
  'https://cdn.prod.website-files.com/661d0e82859b70eb49028b49/668eac48bc2c9d8904a6296f_1718246717392-p-500.jpg',
  'https://i.ibb.co/jT9QpvF/1721915808580.jpg',
  'https://cdn.prod.website-files.com/661d0e82859b70eb49028b49/668eac7315c21ff362ee871d_1706455832666-p-500.jpg',
  'https://cdn.prod.website-files.com/661d0e82859b70eb49028b49/6634f8061b39694f69211f94_1649695679856%20(1).jpg',
  'https://cdn.prod.website-files.com/661d0e82859b70eb49028b49/6634f7eba92af13da7a65005_1700995077154.jpg',
  'https://cdn.prod.website-files.com/661d0e82859b70eb49028b49/667ece14c8853a8ecc2903ef_1693398443094.jpg',
  'https://cdn.prod.website-files.com/661d0e82859b70eb49028b49/667ecf9fa0a60b0eeb0c3c6e_1700869216777.jpg',
  'https://i.ibb.co/Jq4S33L/1711959166182-1.jpg',
];

const currencyOptions = [
  {label: '$ USD', value: 'USD'},
  {label: '€ EUR', value: 'EUR'},
];

export const PricingTable: FunctionComponent = () => {
  const {me} = useContext(UserContext);
  const {products, loading} = useProducts();
  const {trackEvent} = useTracking();
  const [showYearly, setShowYearly] = useState<boolean>(true);
  const [chosenPostsPerMonth, setChosenPostsPerMonth] = useState<number>(0);

  const defaultLanguage = i18n.locale;
  const defaultCurrency =
    defaultLanguage === 'fr' ? currencyOptions[1] : currencyOptions[0];
  const [selectedCurrency, setSelectedCurrency] = useState(defaultCurrency);

  const currency = selectedCurrency.value as 'EUR' | 'USD';

  const productsToDisplay = useMemo(
    () =>
      !me
        ? []
        : products
            .filter(
              (product) =>
                product.period ===
                  (showYearly ? ProductPeriod.YEARLY : ProductPeriod.MONTHLY) &&
                product.currency === currency,
            )
            .sort((a, b) => a.price - b.price),
    [me, products, showYearly, currency],
  );

  const sliderProducts: Product[] = useMemo(() => {
    return products
      .sort((a, b) => a.price - b.price)
      .filter(
        (product) =>
          product.isCreator &&
          product.period ===
            (showYearly ? ProductPeriod.YEARLY : ProductPeriod.MONTHLY) &&
          product.currency === currency,
      );
  }, [products, showYearly, currency]);

  const chosenPostsPerMonthMarks = useMemo(() => {
    return sliderProducts.map((product, index) => ({
      value: index,
      label: `${product.posts}`,
    }));
  }, [sliderProducts]);

  const chosenSliderProduct = useMemo(() => {
    return sliderProducts[chosenPostsPerMonth];
  }, [sliderProducts, chosenPostsPerMonth]);

  const createCheckoutSession = useCreateCheckoutSession();

  const redirectToCheckout = useCallback(
    async (productId: string, productName: string) => {
      trackEvent('Pricing - Click - Choose product', {
        productId: productId,
        productName: productName,
      });
      await createCheckoutSession.mutate({
        productId,
      });
    },
    [createCheckoutSession, trackEvent],
  );

  useEffect(() => {
    if (!createCheckoutSession.isSuccess) {
      return;
    }
    void (async () => {
      window.location.href = await createCheckoutSession.data.text();
    })();
  }, [createCheckoutSession.data, createCheckoutSession.isSuccess]);
  return (
    <Container>
      <CurrencySelectContainer>
        <CurrencySelect>
          <FilterSelect
            selectedOption={selectedCurrency}
            setSelectedOption={setSelectedCurrency}
            options={currencyOptions}
          />
        </CurrencySelect>
      </CurrencySelectContainer>
      <Header>{t`Less time redacting. More time on your business.`}</Header>
      <ProfileImageContainer>
        {creatorsProfileImageUrls.map((url, index) => (
          <ProfileImage key={index} src={url} />
        ))}
      </ProfileImageContainer>
      <TrustedByText>{t`Trusted by +10,000 experts, founders, marketers and professionals`}</TrustedByText>
      <Separator />
      <ButtonTabWrapper>
        <StyledChip label={t`2 months FREE`} variant="success" />
        <StyledArrowIndicator />
        <ButtonTab
          variant={showYearly ? 'annually' : 'monthly'}
          onClick={() => {
            trackEvent('Pricing - Click - Switch period');
            setShowYearly(!showYearly);
          }}
        />
      </ButtonTabWrapper>
      {!loading ? (
        <UpgradePricingCard
          productsToDisplay={productsToDisplay}
          chosenSliderProduct={chosenSliderProduct}
          redirectToCheckout={redirectToCheckout}
          priceToString={priceToString}
          chosenPostsPerMonth={chosenPostsPerMonth}
          setChosenPostsPerMonth={setChosenPostsPerMonth}
          chosenPostsPerMonthMarks={chosenPostsPerMonthMarks}
          productToPricePerPost={(product) => productToPricePerPost(product)}
          loading={createCheckoutSession.isPending}
          isAnnual={showYearly}
          getCurrencySymbol={(useName) => getCurrencySymbol(currency, useName)}
        />
      ) : (
        <ThreeDotsLoader />
      )}
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  color: ${({theme}) => theme.colors.neutral.shade11};
  gap: 1.5em;
`;

const Header = styled.div`
  font-size: 2.2em;
  text-align: center;
  font-weight: 700;
  span {
    color: ${({theme}) => theme.colors.primary1.shade2};
  }
`;

const TrustedByText = styled.div`
  text-align: center;
  font-size: 1em;
  font-weight: 600;
  color: ${({theme}) => theme.colors.neutral.shade9};
`;

const ButtonTabWrapper = styled.div`
  position: relative;
  display: inline-block;
  margin-top: 1em;
`;

const StyledArrowIndicator = styled(ArrowIndicator)`
  position: absolute;
  top: -24px;
  right: -25px;
`;

const StyledChip = styled(Chip)`
  position: absolute;
  top: -24px;
  right: 5px;
  transform: translateX(0.8em);
`;

const ProfileImageContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

const ProfileImage = styled.img`
  width: 50px;
  height: auto;
  border-radius: 25px;
  margin-right: -10px;
`;

const Separator = styled.div`
  width: 20em;
  height: 1px;
  background-color: ${({theme}) => theme.colors.neutral.shade18};
  margin-bottom: 1em;
`;

const CurrencySelectContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
`;

const CurrencySelect = styled.div`
  width: 10em;
  font-size: 1.1em;
`;
