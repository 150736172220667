import {FunctionComponent, useState} from 'react';
import styled, {keyframes} from 'styled-components';
import {Button} from '../../components/atoms/Button';
import {TextInput} from '../../components/atoms/TextInput';
import GenerateIcon from '../../components/atoms/Icons/GenerateIcon';
import ChevronBottom from '../../components/atoms/Icons/ChevronBottom';
import {useSnackbar} from 'notistack';
import CopyIcon from '../../components/atoms/Icons/CopyIcon';
import {useGenerateHeadlines} from '../../hooks/headlineGenerator/useGenerateHeadlines';

export const HeadlineGenerator: FunctionComponent = () => {
  const [showTips, setShowTips] = useState(false);
  const [headlines, setHeadlines] = useState<string[]>([]);
  const [userInput, setUserInput] = useState<string>('');
  const {enqueueSnackbar} = useSnackbar();
  const {mutateAsync: generateHeadlines, isPending: loading} =
    useGenerateHeadlines();

  const handleGenerateHeadlines = async () => {
    try {
      const generatedHeadlines = await generateHeadlines(userInput);
      setHeadlines(generatedHeadlines);
    } catch (error) {
      enqueueSnackbar('Error generating headlines. Please try again.', {
        variant: 'error',
      });
    }
  };

  const copyToClipboard = (headline: string) => {
    navigator.clipboard.writeText(headline).then(() => {
      enqueueSnackbar('Copied to clipboard', {
        variant: 'success',
      });
    });
  };

  return (
    <MainContainer>
      <ToolContainer>
        <Title>I am a ...</Title>
        <InputContainer>
          <TextInput
            placeholderText="Marketing specialist with 10 years experience"
            label="Job Title and Key Skills"
            fullWidth
            value={userInput}
            onChange={(event) => setUserInput(event.target.value)}
          />
          <Button
            label={'Generate'}
            variant="main"
            size="medium"
            startIcon={<GenerateIcon height={21} width={21} />}
            onClick={handleGenerateHeadlines}
            disabled={loading}
          />
        </InputContainer>
        <HeadlinesContainer>
          {loading
            ? Array(3)
                .fill(0)
                .map((_, index) => (
                  <HeadlineBox key={index}>
                    <SkeletonLoader
                      $width={`${Math.floor(Math.random() * 41) + 30}%`}
                      $height="1em"
                    />
                  </HeadlineBox>
                ))
            : headlines.map((headline, index) => (
                <HeadlineBox
                  key={index}
                  onClick={() => copyToClipboard(headline)}>
                  <CopyIconContainer>
                    <CopyIcon width={18} height={18} />
                  </CopyIconContainer>
                  {headline}
                </HeadlineBox>
              ))}
        </HeadlinesContainer>
        {headlines.length > 0 && (
          <Button
            label={'Try Again'}
            variant="inverted"
            size="small"
            onClick={handleGenerateHeadlines}
            disabled={loading}
          />
        )}
        <TipsContainer>
          <TipsHeader onClick={() => setShowTips(!showTips)}>
            Helpful Tips
            <ChevronIcon $isOpen={showTips}>
              <ChevronBottom width={11} height={11} />
            </ChevronIcon>
          </TipsHeader>
          {showTips && (
            <TipsContent>
              <p>
                To achieve better results, we recommend adding more details
                beyond just your job title and company. For instance, you could
                mention what your company specializes in, who your target
                audience is, or the type of content you enjoy posting on
                LinkedIn.
              </p>
              <p>For example:</p>
              <ul>
                <li>
                  Co-founder of RedactAI, specializing in helping professionals
                  build personal brands on LinkedIn
                </li>
                <li>
                  Head of Design at ABC, specializing in user experience and
                  interface design
                </li>
                <li>
                  Marketing Director at ABC, promoting sustainable energy
                  solutions
                </li>
              </ul>
            </TipsContent>
          )}
        </TipsContainer>
      </ToolContainer>
    </MainContainer>
  );
};

const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 60em;
  margin: 0 auto;
  min-height: calc(100vh - 5.5em);
  padding: 0 1.5em;
`;

const ToolContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1em;
  margin: 2em 0 0 0;
  align-items: center;
  border: 2px solid ${({theme}) => theme.colors.neutral.shade5};
  border-radius: 2em;
  padding: 1.5em;
`;

const InputContainer = styled.div`
  display: flex;
  gap: 1em;
  width: 100%;
`;

const Title = styled.h1`
  font-weight: 700;
  font-size: 1.5em;
  color: ${({theme}) => theme.colors.neutral.shade11};
  margin: 0.4em 0;
  align-self: flex-start;
`;

const TipsContainer = styled.div`
  width: 100%;
`;

const TipsHeader = styled.div`
  display: flex;
  align-items: center;
  font-weight: 600;
  font-size: 1em;
  color: ${({theme}) => theme.colors.neutral.shade11};
  cursor: pointer;
  text-align: left;
`;

const ChevronIcon = styled.div<{$isOpen: boolean}>`
  margin-left: 0.5em;
  transition: transform 0.1s ease;
  transform: ${({$isOpen}) => ($isOpen ? 'rotate(180deg)' : 'rotate(0deg)')};
`;

const TipsContent = styled.div`
  margin-top: 0.5em;
  font-size: 1em;
  color: ${({theme}) => theme.colors.neutral.shade9};
  ul {
    margin-top: 0.5em;
    padding-left: 1.5em;
  }
  li {
    margin-bottom: 0.5em;
  }
`;

const fadeIn = keyframes`
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

const shimmer = keyframes`
  0% {
    background-position: -200% 0;
  }
  100% {
    background-position: 200% 0;
  }
`;

const SkeletonLoader = styled.div<{
  $width: string;
  $height: string;
  $borderRadius?: string;
  $marginBottom?: string;
}>`
  width: ${(props) => props.$width};
  height: ${(props) => props.$height};
  border-radius: ${(props) => props.$borderRadius || '0.5em'};
  margin-bottom: ${(props) => props.$marginBottom || '0'};
  background: linear-gradient(90deg, #f0f0f0 25%, #e0e0e0 50%, #f0f0f0 75%);
  background-size: 200% 100%;
  animation: ${shimmer} 1.5s infinite;
`;

const HeadlinesContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1em;
  width: 100%;
`;

const CopyIconContainer = styled.div`
  position: absolute;
  left: 1em;
  top: 50%;
  transform: translateY(-50%);
  opacity: 0;
  display: none;
`;

const HeadlineBox = styled.div`
  padding: 1em;
  border: 2px solid ${({theme}) => theme.colors.primary1.shade1};
  border-radius: 1em;
  background-color: ${({theme}) => theme.colors.neutral.shade1};
  animation: ${fadeIn} 0.5s ease;
  font-size: 1em;
  font-weight: 500;
  color: ${({theme}) => theme.colors.neutral.shade11};
  cursor: pointer;
  transition:
    transform 0.2s ease,
    box-shadow 0.2s ease,
    padding-left 0.2s ease;

  &:hover {
    transform: translateY(-5px);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    padding-left: 3em;

    ${CopyIconContainer} {
      opacity: 1;
      display: block;
    }
  }
`;
