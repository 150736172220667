import {FunctionComponent, useState} from 'react';
import styled from 'styled-components';
import {Button} from '../../components/atoms/Button';
import {TextInput} from '../../components/atoms/TextInput';
import {useSnackbar} from 'notistack';
import {DownloadIcon} from '../../components/molecules/Icons/DownloadIcon';
import LinkedInIcon from '../../components/atoms/Icons/LinkedInIcon';
import {isValidLinkedInPostUrl} from './utils/isValidLinkedinPostUrl';
import {CircularProgress} from '@mui/material';
import {useDownloadVideo} from '../../hooks/videoDownloader/useDownloadVideo';

interface Video {
  url: string;
  previewUrl: string;
}

export const VideoDownloader: FunctionComponent = () => {
  const [videoUrl, setVideoUrl] = useState('');
  const [videos, setVideos] = useState<Video[]>([]);
  const {enqueueSnackbar} = useSnackbar();
  const {mutateAsync: downloadVideoAsync, isPending: loading} =
    useDownloadVideo();

  const handlePrepareVideo = async () => {
    if (!isValidLinkedInPostUrl(videoUrl)) {
      enqueueSnackbar('Please enter a valid LinkedIn video URL', {
        variant: 'error',
      });
      return;
    }

    const newVideo: Video = {
      url: videoUrl,
      previewUrl: '',
    };

    setVideos((prevVideos) => [...prevVideos, newVideo]);

    try {
      const downloadUrl = await downloadVideoAsync(videoUrl);

      setVideos((prevVideos) =>
        prevVideos.map((video) =>
          video.url === videoUrl ? {...video, previewUrl: downloadUrl} : video,
        ),
      );

      enqueueSnackbar('Video prepared successfully', {
        variant: 'success',
      });
    } catch (error) {
      enqueueSnackbar('An error occurred while preparing the video', {
        variant: 'error',
      });
    }
  };

  const downloadVideo = (downloadUrl: string) => {
    const a = document.createElement('a');
    a.href = downloadUrl;
    a.download = 'linkedin-video-RedactAi.mp4';
    a.click();
    enqueueSnackbar('Video downloaded successfully', {
      variant: 'success',
    });
  };

  return (
    <MainContainer>
      <ToolContainer>
        <Title>Enter LinkedIn Video URL</Title>
        <InputContainer>
          <TextInput
            placeholderText="https://www.linkedin.com/posts/username"
            label="Video URL"
            value={videoUrl}
            onChange={(e) => setVideoUrl(e.target.value)}
            fullWidth
          />
          <Button
            label={loading ? 'Preparing...' : 'Download Your Video'}
            variant="main"
            size="medium"
            onClick={handlePrepareVideo}
            disabled={loading}
          />
        </InputContainer>
        <VideosContainer>
          {videos.map((video, index) => (
            <VideoCard key={index}>
              {video.previewUrl ? (
                <>
                  <VideoPreviewContainer>
                    <VideoPreview controls>
                      <source src={video.previewUrl} type="video/mp4" />
                      Your browser does not support the video tag.
                    </VideoPreview>
                  </VideoPreviewContainer>
                  <ButtonContainer>
                    <Button
                      label="Save To Your Device"
                      variant="outlined"
                      size="medium"
                      onClick={() => downloadVideo(video.previewUrl)}
                      endIcon={<DownloadIcon color="#000000" size="25" />}
                    />
                    <Button
                      label="Post On LinkedIn"
                      variant="main"
                      size="medium"
                      onClick={() => window.open('https://www.redactai.io/')}
                      endIcon={<LinkedInIcon color="#000000" />}
                    />
                  </ButtonContainer>
                </>
              ) : (
                loading && <StyledCircularProgress size={'5em'} />
              )}
            </VideoCard>
          ))}
        </VideosContainer>
      </ToolContainer>
    </MainContainer>
  );
};

const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 60em;
  margin: 0 auto;
  min-height: calc(100vh - 5.5em);
  padding: 0 1.5em;
`;

const ToolContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1em;
  margin: 2em 0 0 0;
  align-items: center;
  border: 2px solid ${({theme}) => theme.colors.neutral.shade5};
  border-radius: 2em;
  padding: 1.5em;
`;

const InputContainer = styled.div`
  display: flex;
  gap: 1em;
  width: 100%;
`;

const Title = styled.h1`
  font-weight: 700;
  font-size: 1.5em;
  color: ${({theme}) => theme.colors.neutral.shade11};
  margin: 0.4em 0;
  align-self: flex-start;
`;

const VideosContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 1em;
  justify-content: center;
  width: 100%;
`;

const VideoCard = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 1em;
  padding: 1em;
  width: 100%;
  min-width: 30em;
`;

const VideoPreviewContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 1em;
`;

const VideoPreview = styled.video`
  max-width: 100%;
  border-radius: 1em;
`;

const ButtonContainer = styled.div`
  display: flex;
  gap: 1em;
`;

const StyledCircularProgress = styled(CircularProgress)`
  color: ${({theme}) => theme.colors.primary1.shade2};
`;
