import {useQuery} from '@tanstack/react-query';
import {useContext, useMemo} from 'react';
import {fetchApi} from '../../utils/fetchApi';
import {SavedPostLanguage, SavedPostType} from '../savedPost/savedPost.types';
import {UserContext} from '../../common/UserContext/user.context.ts';
import {savedPostsMock} from './savedPosts.mock.ts';

export interface SavedPostDto {
  id: string;
  subject: string;
  ideas?: string;
  text: string;
  isUsed: boolean;
  profileUrl?: string;
  contentUrl?: string;
  recycledPost?: string;
  createdAt: Date;
  language?: SavedPostLanguage;
  isLiked?: boolean;
  isFavourite: boolean;
}

const savedPostDtoToSavedPostType = (
  name: string,
  post: SavedPostDto,
): SavedPostType => {
  return {
    id: post.id,
    authorName: name,
    postContent: post.text,
    isUsed: post.isUsed,
    isLiked: post.isLiked,
    createdAt: new Date(post.createdAt),
    profileUrl: post.profileUrl,
    language: post.language,
    isFavourite: post.isFavourite,
  };
};

export const useSavedPosts = () => {
  const {me, loading: loadingUser} = useContext(UserContext);
  const {data, isLoading, refetch} = useQuery({
    queryKey: ['savedPosts'],
    queryFn: async () => {
      if (
        import.meta.env.VITE_NODE_ENV === 'development' &&
        import.meta.env.VITE_WITH_AI !== 'true'
      ) {
        const mockData = await savedPostsMock();
        return mockData;
      }
      return fetchApi(`/savedPosts`);
    },
    enabled: !!me,
  });
  const savedPosts: SavedPostType[] = useMemo(
    () =>
      !data
        ? []
        : data.map((post: SavedPostDto) =>
            savedPostDtoToSavedPostType(
              `${me?.firstName} ${me?.lastName}`,
              post,
            ),
          ),
    [me, data],
  );

  return {
    savedPosts,
    loading: isLoading || loadingUser,
    refetch,
  };
};
