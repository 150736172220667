import {FunctionComponent, useEffect, useState} from 'react';
import styled from 'styled-components';
import {t} from '@lingui/macro';
import {useSnackbar} from 'notistack';
import {isTablet} from '../../../utils/responsive';
import {Button} from '../../../components/atoms/Button';
import {
  RedactPostStyle,
  RedactStyleSource,
} from '../../redactpost/components/RedactPostStyle';
import {RecyclePostCard} from '../../../components/molecules/RecyclePostCard';
import {RedactContentSource} from '../../redactpost/components/RedactPostContent';
import {RedactPostGeneration} from '../../redactpost/components/RedactPostGeneration';
import {isValidLinkedInProfileUrl} from '../../../utils/isValidLinkedInProfileUrl';
import {useRedactPost} from '../../../hooks/redactPost/useRedactPost';
import useDefaultLanguage from '../../../hooks/postLang/useDefaultLanguage';
import {usePostToneOptions} from '../../../hooks/usePostToneOptions';
import {Post} from '../RecyclePost';
import ArrowRight from '../../../components/atoms/Icons/ArrowRightIcon';
import {PricingPopup} from '../../../components/organisms/PricingPopup';
import {useLastPostLanguage} from '../../../hooks/redactPost/useLastPostLanguage';

interface RecyclePostProps {
  postToBeRecycled: Post;
  setIsRecycling: (value: boolean) => void;
}

export const RecyclePostForm: FunctionComponent<RecyclePostProps> = ({
  postToBeRecycled,
  setIsRecycling,
}) => {
  const postToneOptions = usePostToneOptions();
  const {enqueueSnackbar} = useSnackbar();
  const [postSubject, setPostSubject] = useState<string>('');
  const [postTone, setPostTone] = useState(postToneOptions[0].value);
  const [postLang, setPostLang] = useDefaultLanguage();
  const [profileUrl, setProfileUrl] = useState<string>('');
  const [styleSource, setStyleSource] = useState<RedactStyleSource>(
    RedactStyleSource.PROFILE_URL,
  );
  const [readyToRedact, setReadyToRedact] = useState<boolean>(false);
  const [profileUrlError, setProfileUrlError] = useState<string>('');
  const [isSubmitted, setIsSubmitted] = useState<boolean>(false);
  const [isNewPost, setIsNewPost] = useState<boolean>(true);
  const lastUsedLanguage = useLastPostLanguage();

  useEffect(() => {
    setPostLang(lastUsedLanguage);
  }, [setPostLang, lastUsedLanguage]);

  useEffect(() => {
    if (postToBeRecycled) {
      setPostSubject(postToBeRecycled.content.split(' ').slice(0, 5).join(' '));
    }
  }, [postToBeRecycled]);

  const {
    onGeneratePostSubmit,
    redactedPosts: posts,
    isLoading,
    showPricingPopup,
    setShowPricingPopup,
    error,
  } = useRedactPost(
    postSubject,
    postToBeRecycled.content,
    '',
    false,
    styleSource === RedactStyleSource.PROFILE_URL,
    profileUrl,
    postTone,
    postLang,
  );

  const onWritePost = () => {
    setIsSubmitted(true);
    if (!postToBeRecycled) {
      enqueueSnackbar(t`Please provide a post to recycle.`, {
        variant: 'error',
      });
      return;
    }
    if (
      styleSource === RedactStyleSource.PROFILE_URL &&
      !isValidLinkedInProfileUrl(profileUrl)
    ) {
      const errorMessage = t`Please ensure the LinkedIn profile URL is correct and accessible.`;
      setProfileUrlError(errorMessage);
      enqueueSnackbar(errorMessage, {
        variant: 'error',
      });
      return;
    }
    setIsNewPost(false);
    setIsSubmitted(false);
    onGeneratePostSubmit();
    setReadyToRedact(true);
  };

  const onReset = () => {
    setIsNewPost(true);
    setPostSubject('');
    setPostTone(postToneOptions[0].value);
    setStyleSource(RedactStyleSource.PROFILE_URL);
    setReadyToRedact(false);
    setProfileUrlError('');
  };

  useEffect(() => {
    if (showPricingPopup || error) {
      setReadyToRedact(false);
    }
  }, [showPricingPopup, error]);

  return (
    <>
      {readyToRedact ? (
        <Wrapper>
          <RedactPostGeneration
            posts={posts}
            contentSource={RedactContentSource.RECYCLE_POST}
            styleSource={styleSource}
            onChangeInputs={() => {
              setReadyToRedact(false);
              setIsNewPost(false);
            }}
            onNewPost={() => setIsRecycling(false)}
            isLoading={isLoading}
          />
        </Wrapper>
      ) : (
        <>
          <Title>{t`Recycle This Post`}</Title>
          <Container>
            <RecyclePostCard
              post={postToBeRecycled}
              isRecycleForm
              setIsRecycling={setIsRecycling}
            />
            <RedactPostStyle
              styleSource={styleSource}
              setStyleSource={setStyleSource}
              setPostTone={setPostTone}
              postTone={postTone}
              postLang={postLang}
              setPostLang={setPostLang}
              profileUrl={profileUrl}
              setProfileUrl={setProfileUrl}
              profileUrlError={profileUrlError}
              isSubmitted={isSubmitted}
              isNewPost={isNewPost}
            />
            <End>
              <Button
                label={t`Reset`}
                size="medium"
                variant="light"
                onClick={onReset}
              />
              <Button
                onClick={onWritePost}
                label={t`Write my post`}
                size="medium"
                variant="main"
                endIcon={<StyledArrowRight width={22} height={23} />}
              />
            </End>
            {showPricingPopup && (
              <PricingPopup onClose={() => setShowPricingPopup(false)} />
            )}
          </Container>
        </>
      )}
    </>
  );
};

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2em;
  width: 80%;
  @media (max-width: ${isTablet}) {
    width: 100%;
  }
`;

const Title = styled.h1`
  font-size: 1.5em;
  margin-bottom: 1.2em;
  margin-top: 0em;
  font-weight: 700;
  color: ${({theme}) => theme.colors.neutral.shade11};
`;

const End = styled.div`
  width: 100%;
  display: flex;
  gap: 1.25em;
  justify-content: end;
  @media (max-width: ${isTablet}) {
    flex-direction: column-reverse;
    gap: 1.5em;
  }
`;

const StyledArrowRight = styled(ArrowRight)`
  @media (max-width: ${isTablet}) {
    height: 1.5em;
    width: 1.5em;
  }
`;
