import {
  forwardRef,
  FunctionComponent,
  HTMLProps,
  useMemo,
  useState,
} from 'react';
import {Page} from '../../components/templates/Page';
import {useSavedPosts} from '../../hooks/savedPostsAndIdeas/useSavedPosts';
import styled from 'styled-components';
import {isSmallScreen, isTablet} from '../../utils/responsive';
import SearchBar from '../../components/atoms/SearchBar';
import {FilterSelectUsedNotUsed} from '../../components/molecules/FilterSelectUsedNotUsed';
import {LightTab} from '../../components/atoms/LightTab';
import HistoryIcon from '../../components/atoms/Icons/HistoryIcon';
import {t} from '@lingui/macro';
import {GeneratedIdea} from '../../components/molecules/GeneratedIdea';
import {SavedPost} from '../../components/molecules/SavedPost';
import {VirtuosoGrid} from 'react-virtuoso';

export const gridComponents = {
  List: forwardRef<HTMLDivElement, HTMLProps<HTMLDivElement>>(
    ({style, children, ...props}, ref) => (
      <div
        ref={ref}
        {...props}
        style={{
          display: 'flex',
          gap: '1.5em',
          flexWrap: 'wrap',
          justifyContent: 'center',
          ...style,
        }}>
        {children}
      </div>
    ),
  ),
  Item: ({children, ...props}: HTMLProps<HTMLDivElement>) => (
    <div {...props}>{children}</div>
  ),
};

export const MyPosts: FunctionComponent = () => {
  const {savedPosts: posts, loading} = useSavedPosts();
  const [searchFilter, setSearchFilter] = useState('');
  const [usedFilter, setUsedFilter] = useState<string>(t`All`);
  const [isFavourites, setIsFavourites] = useState<boolean>(false);

  const filteredPosts = useMemo(
    () =>
      [
        ...posts.filter((post) => {
          if (isFavourites && !post.isFavourite) {
            return false;
          }
          if (usedFilter === t`Not Used` && post.isUsed) {
            return false;
          }
          if (usedFilter === t`Used` && !post.isUsed) {
            return false;
          }
          if (
            searchFilter &&
            !post.postContent.toLowerCase().includes(searchFilter.toLowerCase())
          ) {
            return false;
          }
          return true;
        }),
      ].sort((post1, post2) => {
        const timeDiff = post2.createdAt.getTime() - post1.createdAt.getTime();
        if (timeDiff === 0) {
          return post2.id.localeCompare(post1.id);
        }
        return timeDiff;
      }),
    [posts, searchFilter, usedFilter, isFavourites],
  );
  return (
    <Page>
      <ContentContainer>
        <Between>
          <Tabs>
            <LightTab
              isSelected={!isFavourites}
              label={t`All posts`}
              icon={<HistoryIcon />}
              onClick={() => setIsFavourites(false)}
            />
            <LightTab
              isSelected={isFavourites}
              label={t`Favourites`}
              icon={<HistoryIcon />}
              onClick={() => setIsFavourites(true)}
            />
            <FilterSelectUsedNotUsed {...{usedFilter, setUsedFilter}} />
          </Tabs>
          <StyledSearchBar {...{searchFilter, setSearchFilter}} />
        </Between>
        {loading ? (
          <Ideas>
            {Array.from({length: 3}, (_, index) => (
              <GeneratedIdea key={index} isLoading={true} width="22em" />
            ))}
          </Ideas>
        ) : (
          <VirtuosoContainer>
            <VirtuosoGrid
              data={filteredPosts}
              style={{height: '100%'}}
              components={gridComponents}
              itemContent={(index, post) => (
                <SavedPost key={index} post={post} width="22em" height="34em" />
              )}
            />
          </VirtuosoContainer>
        )}
      </ContentContainer>
    </Page>
  );
};

const ContentContainer = styled.div`
  background-color: ${({theme}) => theme.colors.neutral.shade1};
  border-radius: 1.5em;
  padding: 1.5em;
  display: flex;
  flex-direction: column;
  gap: 1.5em;
`;

const Tabs = styled.div`
  display: flex;
  align-items: center;
  gap: 2em;
`;

const Between = styled.div`
  display: flex;
  align-items: center;
  gap: 2em;
  @media (max-width: ${isTablet}) {
    flex-direction: column;
    align-items: start;
    gap: 1.5em;
  }
`;

const Ideas = styled.div`
  display: flex;
  gap: 1.5em;
  flex-wrap: wrap;
  justify-content: center;
`;

const StyledSearchBar = styled(SearchBar)`
  flex-grow: 1;
  @media (max-width: ${isTablet}) {
    width: 100%;
  }
`;

const VirtuosoContainer = styled.div`
  height: calc(100vh - 12em);
  @media (max-width: ${isSmallScreen}) {
    height: calc(100vh - 18em);
  }
  @media (max-width: ${isTablet}) {
    height: calc(100vh - 21em);
  }
`;
